import jwt from 'jsonwebtoken';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { selectOnboardingData } from '../../store/slices/global.slice';
import { SplitContainer, QuoteAndReview, Alert } from '@musiversal/musiversal-components';
import Icon from '../../components/Atoms/Icon/Icon';
import Button from '../../components/Atoms/v3/Button/Button';
import Input from '../../components/Atoms/v3/Input/Input';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { useTrackPageViewedEvent } from '../../hooks/useTrackPageView';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { InputTypes } from '../../enums/input-types.enum';
import AuthService from '../../services/auth/auth.service';
import styles from './PasswordValidate.module.scss';

import {
  reviewQuote,
  reviewImage,
  reviewName,
  reviewSubtitle,
  reviewIcon
} from './../review-and-quote.const';

import {
  backToLoginText,
  backToPasswordResetText,
  buttonSubmittingText,
  buttonText,
  header,
  invalidTokenText,
  passwordChangedText
} from './password-validate.const';

const PasswordValidate = (): ReactElement => {
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  useTrackPageViewedEvent();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch
  } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [submitting, setSubmitting] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [passwordSuccessfullyChanged, setPasswordSuccessfullyChanged] = useState(false);

  const { token } = useParams<{ token: string }>();

  useEffect((): void => {
    const decodedToken = jwt.decode(token);
    if (new Date(decodedToken.exp * 1000) < new Date()) {
      return;
    }
    const validateToken = async (): Promise<void> => {
      try {
        await AuthService.resetPasswordToken({ resetToken: token });
        setIsTokenValid(true);
      } catch (e) {
        return;
      }
    };
    validateToken().finally();
  }, [token]);

  const onSubmit = async data => {
    setSubmitting(true);

    const payload = {
      resetToken: token,
      password: data.password
    };

    try {
      const res = await AuthService.resetPasswordToken(payload);
      if (res.status === 200) {
        setSubmitting(false);
        setPasswordSuccessfullyChanged(true);
        return;
      }
    } catch (err) {
      if (err.response.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          <div className={styles.logo}>
            <Icon name={'musiversal-logo'} />
          </div>
          <div className={styles.signupHeader}>
            <h1>{header}</h1>
          </div>
          {passwordSuccessfullyChanged ? (
            <>
              <Alert
                    body={passwordChangedText}
                    template={'success'}
                  />
              <Link to={'/login'} className={styles.backToLogin}>
                {backToLoginText}
              </Link>
            </>
          ) : (
            <>
              {isTokenValid ? (
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                  <Input
                    placeholder={'Password'}
                    type={InputTypes.PASSWORD}
                    name={'password'}
                    id={'password'}
                    registerRef={register('password', {
                      required: 'You must specify a password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                    errors={[errors.password?.message]}
                    clearErrors={() => clearErrors()}
                  />
                  <Input
                    placeholder={'Confirm password'}
                    type={InputTypes.PASSWORD}
                    name={'password_confirmation'}
                    id={'password_confirmation'}
                    registerRef={register('password_confirmation', {
                      validate: value => value === watch('password') || 'The passwords do not match'
                    })}
                    errors={[errors.server?.message, errors.password_confirmation?.message]}
                    clearErrors={() => clearErrors()}
                  />
                  <div className={styles.button}>
                    <Button
                      disabled={submitting}
                      width={'100%'}
                      buttonSize={ButtonSizes.MEDIUM}
                      buttonText={submitting ? buttonSubmittingText : buttonText}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </form>
              ) : (
                <>
                  <div className={styles.infoText}>{invalidTokenText}</div>
                  <Link to={'/reset-password'} className={styles.backToLogin}>
                    {backToPasswordResetText}
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      }
      rightColumn={
        <div className={styles.rightColumnContainer}>
          <QuoteAndReview
            quote={onboardingData?.persona?.Review?.review_copy ?? reviewQuote}
            image={onboardingData?.persona?.Review?.avatar?.url ?? reviewImage}
            name={onboardingData?.persona?.Review?.user_name ?? reviewName}
            subtitle={onboardingData?.persona?.Review?.user_description ?? reviewSubtitle}
            icon={reviewIcon}
          />
        </div>
      }
    />
  );
};

export default PasswordValidate;
