import { UserStatuses } from '../enums/user-statuses.enum';
import { PaymentProviders } from '../enums/payment-providers.enum';
import { Environments } from '../enums/environments.enum';
import { setIsShowGlobalLoader } from '../store/slices/global.slice';
import { localStorageKeys } from '../constants/localStorageKeys.const';
import PaypalService from '../services/subscriptions/paypal.service';
import SubscriptionService from '../services/subscriptions/stripe.service';

export const executeResumeSubscription = async (
  user: User,
  plans: Plan[],
  dispatch: Dispatch<any>,
  history: History
): Promise<void> => {
  // console.log(user);
  if (user.subscription?.paymentsProvider === PaymentProviders.PAYPAL) {
    dispatch(setIsShowGlobalLoader(true));
    // dispatch(setIsShowSubscriptionModal(true));
    localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
    let paypalPlan = null;
    if (process.env.REACT_APP_NODE_ENV === Environments.PRODUCTION) {
      paypalPlan = plans[0].paypalProdPriceId;
    } else {
      paypalPlan = plans[0].paypalDevPriceId;
    }
    try {
      const r = await PaypalService.createPaypalSubscription(paypalPlan);
      const { url } = r.data;
      window.location.href = url;
      dispatch(setIsShowGlobalLoader(false));
      return;
    } catch (e) {
      console.error(e);
    }
    dispatch(setIsShowGlobalLoader(false));
  } else {
    dispatch(setIsShowGlobalLoader(true));
    localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
    try {
      await SubscriptionService.renewSubscription();
      history.push('/profile#billing?session_id=true');
    } catch (e) {
      dispatch(setIsShowGlobalLoader(false));
      console.error(e);
    }
  }
};