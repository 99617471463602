import { Button, Spinner, NavTabs, CardSession, Illustration } from '@musiversal/musiversal-components';
import moment from 'moment-timezone';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import SessionDetails from '../../../../components/Atoms/SessionDetail/SessionDetails';

/* Components */
import ToastPortal from '../../../../components/Atoms/v3/ToastPortal/ToastPortal';
import SidePane from '../../../../components/Molecules/SidePane/SidePane';
import { SessionStatus } from '../../../../enums/session-status.enum';
import { SessionType } from '../../../../enums/session-type.enum';

/* Enums */
import { SessionsTabs } from '../../../../enums/sessions-tabs.enum';
import { getThread } from '../../../../store/actions/messages.actions';
import { getCurrentlyBookingArtistConfig } from '../../../../store/actions/musicians.actions';

/* Stores and slices */
import { getSessions, getTracks } from '../../../../store/actions/sessions.actions';
import { setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { setSession } from '../../../../store/slices/sessions.slice';
import { setIsShowSidePanel, setIsFullSessionLoaded } from '../../../../store/slices/sidePanel.slice';
import { selectUser } from '../../../../store/slices/user.slice';

/* Store & Slices */
import { RootState } from '../../../../store/store';
import AlertsHandler from './../../../../components/Molecules/v3/AlertsHandler/AlertsHandler';
import Shortcuts from './../../../../components/Molecules/v3/Shortcuts/Shortcuts';

/* Styles */
import styles from './Sessions.module.scss';
import { DEFAULT_CONFIG } from './sessions.const';

const parseSessions = sessions => ({
  [SessionsTabs.UPCOMING]:
    sessions
      ?.filter(x => x?.type === SessionType.UPCOMING)
      ?.sort((a, b) => moment(a.start_date) - moment(b.start_date)) || [],
  [SessionsTabs.PAST]:
    sessions?.filter(x => x?.type === SessionType.PAST)?.sort((a, b) => moment(b.start_date) - moment(a.start_date)) ||
    []
});

const Sessions = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { timezone } = useSelector(selectUser, shallowEqual);
  const { sessions, isSessionsLoading, session, tracks } = useSelector(state => state.sessionsSlice, shallowEqual);
  const [selectedTab, setSelectedTab] = useState(SessionsTabs.UPCOMING);
  const [sessionsToShow, setSessionsToShow] = useState(parseSessions(sessions));
  const [selectedMusicianConfiguration, setSelectedMusicianConfiguration] = useState(null);
  const { session_id: sessionId } = useParams();

  const { isShowSidePanel, isFullSessionLoaded } = useSelector(
    (state: RootState) => state.sidePanelSlice,
    shallowEqual
  );

  const getMusicianConfiguration = useCallback(async musician => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/partners/${musician.id}`, {
        withCredentials: true
      });
      const config = res.data;
      // console.log(res.data);
      setSelectedMusicianConfiguration(config);
      return config;
    } catch (err) {
      setSelectedMusicianConfiguration(DEFAULT_CONFIG);
    }
  }, []);

  const showSession = useCallback(
    async selectedSession => {
      if (isFullSessionLoaded) {
        return;
      }
      dispatch(setIsShowGlobalLoader(true));
      const {
        thread_id: threadId,
        partner,
        partner: { slug }
      } = selectedSession;
      try {
        const res = await Promise.all([
          dispatch(getThread({ threadId })),
          getMusicianConfiguration(partner),
          dispatch(getCurrentlyBookingArtistConfig(slug))
        ]);

        // if (res.every(val => !!val)) {
        dispatch(setIsShowSidePanel(true));
        // }
        dispatch(setSession(selectedSession));
        dispatch(setIsFullSessionLoaded(true));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setIsShowGlobalLoader(false));
      }
    },
    [dispatch, getMusicianConfiguration]
  );

  const changeSelectedTab = useCallback(
    selection => {
      if (selection === selectedTab) {
        return;
      }
      setSelectedTab(selection);
      setSessionsToShow(parseSessions(sessions));
    },
    [sessions, selectedTab]
  );

  /* Closes the side panel when the user clicks on the close button */
  const onPanelClose = () => {
    dispatch(setSession(null));
    dispatch(setIsFullSessionLoaded(false));
    history.push('/sessions');
    dispatch(setIsShowSidePanel(false));
  };

  useEffect(() => {
    if (!sessions?.filter(x => !!x).length || !sessionId) {
      return;
    }
    const sessionFromParam = sessions?.find(({ id }) => id?.toString() === sessionId);
    if (!sessionFromParam) {
      history.push('/sessions');
      return;
    }
    const parsedSession = parseSessions([sessionFromParam]);
    let tab = Object.keys(parsedSession).filter(sessionType => parsedSession[sessionType]?.length)[0];
    if (tab !== SessionsTabs.PAST) {
      tab = SessionsTabs.UPCOMING;
    }
    changeSelectedTab(tab);
    showSession(sessionFromParam).finally();
  }, [sessions, sessionId, showSession, changeSelectedTab, history]);

  useEffect(() => {
    const getData = async () => {
      const promises = [await dispatch(getSessions())];
      if (!tracks?.length) {
        promises.push(dispatch(getTracks()));
      }
      await Promise.all(promises);
    };
    getData().finally();
  }, [dispatch, tracks?.length]);

  useEffect(() => {
    setSessionsToShow(parseSessions(sessions));
  }, [sessions]);

  const counterSessionByType = sessionType => sessionsToShow[sessionType].length;

  const camelizeTitle = title => {
    const lowercasedTitle = title?.toLowerCase() || '';
    const words = lowercasedTitle?.split(' ');
    return words.map(word => word.toLowerCase().charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <AlertsHandler section='my_sessions' />
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>My Sessions</h1>
          <div className={styles.headerButton}>
            <Button buttonText='Book a session' onClick={() => history.push('/explore')} />
          </div>
        </div>

        {isSessionsLoading ? (
          <Spinner className={styles.spinner} bigSize={true} />
        ) : (
          <NavTabs
            tabs={[
              {
                label: 'Upcoming',
                badge: `${counterSessionByType(SessionsTabs.UPCOMING)}`,
                selected: selectedTab === SessionsTabs.UPCOMING,
                onClick: () => changeSelectedTab(SessionsTabs.UPCOMING)
              },
              {
                label: 'Previous',
                badge: `${counterSessionByType(SessionsTabs.PAST)}`,
                selected: selectedTab === SessionsTabs.PAST,
                onClick: () => changeSelectedTab(SessionsTabs.PAST)
              }
            ]}
          />
        )}

        <div className={styles.sessionsList}>
          {sessionsToShow[selectedTab].map(session => (
            <CardSession
              // id={session.id} // Needed for file upload
              key={session.id}
              // rating={session.rating} // Part of shortcut
              avatarUrl={session?.partner?.avatar}
              // camelize title
              title={camelizeTitle(session?.partner?.title)}
              name={session?.partner?.name}
              trackName={session?.session_info?.title}
              service={session?.session_info?.options}
              // musicianConfig={selectedMusicianConfiguration} // Replaced?
              dateTime={session.start_date}
              timezone={timezone}
              onClick={() => showSession(session)}
              isMoved={session?.admin_notes !== null && session?.admin_notes !== undefined}
              isCancelled={session?.status === SessionStatus.CANCELLED}
              shortcuts={<Shortcuts session={session} />}
              // status={session?.status}
              // sessionType={session?.type}
              // start_date={session?.start_date}
            />
          ))}

          {sessionsToShow[selectedTab].length === 0 && (
            <div className={styles.emptyState}>
              <div className={styles.illustration}>
                <Illustration name='tick' />
              </div>
              <h3 className={styles.title}>{"You're all caught up"}</h3>
              <p className={styles.description}>
                {"You don't have any "}
                {selectedTab.includes(SessionsTabs.UPCOMING) ? SessionsTabs.UPCOMING : 'completed'} sessions, yet!
              </p>
            </div>
          )}
        </div>

        <SidePane isOpen={isShowSidePanel} onRequestClose={onPanelClose}>
          {session && (
            <SessionDetails musicianConfiguration={selectedMusicianConfiguration} onPanelClose={onPanelClose} />
          )}
        </SidePane>

        <ToastPortal />
      </div>
    </div>
  );
};

export default Sessions;
