import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState, ReactElement, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  AnnouncementTemplateTypes,
  AnnouncementTypes,
  AnnouncementCategoryLabels,
  AnnouncementButtonBehaviours
} from '../../../enums/announcement-types.enum';
import { getUserCohortBillboard } from '../../../helpers/cohorts.helper';
import { Mixpanel } from '../../../helpers/mixpanel.helper';
import { useIsMobileResolutionGrid, useSmallScreenResolution } from '../../../hooks/useIsMobileResolution';
import StrapiService from '../../../services/cms/cms.service';
import { selectBannerContent, setBannerHeight } from '../../../store/slices/global.slice';
import {
  setIsUpsellPlansModal,
  setIsShowPlansModal,
  setIsShowTopUpModal,
  setIsShowPaywallModal
} from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Button from '../../Atoms/Button/Button.js';
import Icon from '../../Atoms/Icon/Icon';
import styles from './Billboard.module.scss';

const billingAreaPath = '/profile#billing';

const Billboard = (): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const [announcements, setAnnouncements] = useState(null);

  const isMobileResolution = useIsMobileResolutionGrid();
  const isSmallScreenResolution = useSmallScreenResolution();

  const filterAnnouncements = (announcements: any[]) => {
    if (user && user?.sessions_booked === 0) {
      // If filter is empty, return another filter, if that is empty then return the original array
      const zeroBookingsAnnouncements = announcements.filter((announcement: any) => announcement?.showStrategy === AnnouncementTypes.ZERO_BOOKINGS);
      // Currently there is only 1 type
      const defaultAnnouncements = announcements.filter((announcement: any) => announcement?.showStrategy !== AnnouncementTypes.ZERO_BOOKINGS);
      const selectedAnnouncements = zeroBookingsAnnouncements.length > 0 ? zeroBookingsAnnouncements :
                defaultAnnouncements;
      return selectedAnnouncements;
    }
    return announcements;
  };

  const getAnnouncements = async () => {
    const cohort = getUserCohortBillboard(user);
    try {
      const { default: defaultAnnouncement } = (await StrapiService.getAnnouncementsRequest(cohort)).data;
      defaultAnnouncement.announcements = filterAnnouncements(defaultAnnouncement.announcements);
      setAnnouncements(defaultAnnouncement);
    } catch (e) {
      console.error(e);
    }
  };

  const applyAnnouncementImage = announcement => {
    if (isSmallScreenResolution && !isMobileResolution) {
      // 2 cards grid
      return announcement?.mediumImage?.url;
    }
    if (isMobileResolution) {
      return announcement?.smallMobile?.url;
    }
    return announcement?.bigImage?.url;
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  const onButtonClick = (announcement): void => {
    const { buttonCTA } = announcement || {};
    const { buttonBehaviour, buttonURL } = buttonCTA || {};
    const cohort = getUserCohortBillboard(user);
    Mixpanel.track('Announcement Button Clicked', {
      Announcement: announcement?.header,
      'Announcement Category': announcement?.category_text,
      'Button Behaviour': buttonBehaviour,
      'User Status': user.status,
      'Cohort billboard': cohort,
      'Mobile view': isMobileResolution
    });

    if (buttonBehaviour === AnnouncementButtonBehaviours.EXTERNAL_LINK) {
      window.open(buttonURL, '_blank');
    }
    if (buttonBehaviour === AnnouncementButtonBehaviours.INTERNAL_LINK) {
      window.open(buttonURL, '_self');
    }
    // if (buttonBehaviour === AnnouncementButtonBehaviours.OPEN_PLANS_MODAL){
    //   dispatch(setIsShowPlansModal(true));
    // }
    if (
      buttonBehaviour === AnnouncementButtonBehaviours.OPEN_PLANS_MODAL ||
      buttonBehaviour === AnnouncementButtonBehaviours.OPEN_PAYWALL
    ) {
      dispatch(setIsShowPaywallModal(true));
    }
    if (buttonBehaviour === AnnouncementButtonBehaviours.OPEN_UPSELL_MODAL) {
      dispatch(setIsUpsellPlansModal(true));
    }
    if (buttonBehaviour === AnnouncementButtonBehaviours.OPEN_TOPUP_MODAL) {
      dispatch(setIsShowTopUpModal({ isShown: true }));
    }
  };

  const renderButton = (announcement, onButtonClick): ReactElement => {
    const { buttonBehaviour, buttonStyle, ButtonIcon, icon } = announcement?.buttonCTA;
    const IconMapping = {
      play: 'play-circle'
    };

    return (
      <Button
        className={classNames(styles.button, styles[buttonStyle], styles[announcement.category], styles.centerButton)}
        type='submit'
      >
        {ButtonIcon ? (
          <div className={styles.buttonTextWithIcon}>
            {announcement?.buttonCTA?.copy}
            <span className={styles.buttonIcon}>
              <Icon name={'play-circle'} className={styles.icon} />
            </span>
          </div>
        ) : (
          <div className={styles.buttonText}>{announcement?.buttonCTA?.copy}</div>
        )}
      </Button>
    );
  };

  const renderFullAnnouncement = (announcement): ReactElement => (
    <div
      onClick={() => onButtonClick(announcement)}
      className={styles.container}
      style={{
        backgroundSize: `${isMobileResolution ? 'cover' : 'cover'}`,
        backgroundImage: `url('${applyAnnouncementImage(announcement)}')`
      }}
    >
      <div className={classNames(styles.wrapper, styles[announcement.category])}>
        <div className={styles.contentSection}>
          <div className={styles.contentTop}>
            <div className={styles.category}>
              {announcement.category_text ?? AnnouncementCategoryLabels[announcement.category] ?? 'NEWS'}
            </div>
            <div className={styles.header}>{announcement.header}</div>
            {/* {!isMobileResolution && ( */}
            <div className={styles.subheader}>{announcement.subheader}</div>
            {/* )} */}
          </div>
          {renderButton(announcement, onButtonClick)}
        </div>
      </div>
    </div>
  );

  const renderMidSizeAnnouncement = (announcement): ReactElement => (
    <div
      onClick={() => onButtonClick(announcement)}
      className={classNames(styles.container, styles.mediumContainer)}
      style={{
        backgroundPosition: '27% center',
        backgroundSize: 'cover',
        backgroundImage: `url('${announcement?.mediumImage?.url}')`
      }}
    >
      <div className={classNames(styles.wrapper, styles[announcement.category])}>
        <div className={classNames(styles.contentSection, styles.mediumContentSection)}>
          <div className={styles.category}>
            {announcement.category_text ?? AnnouncementCategoryLabels[announcement.category] ?? 'NEWS'}
          </div>
          <div className={styles.header}>{announcement.header}</div>
          {!isMobileResolution && <div className={styles.subheader}>{announcement.subheader}</div>}
          {renderButton(announcement, onButtonClick)}
        </div>
      </div>
    </div>
  );

  const renderBigAnnouncement = (announcement, position = 'inLeft'): ReactElement => (
    <div onClick={() => onButtonClick(announcement)} className={classNames(styles.bigContainer, styles[position])}>
      <div
        className={classNames(styles.wrapper, styles[announcement.category])}
        style={{
          borderRadius: '8px',
          backgroundSize: 'cover',
          backgroundImage: `url('${
            isMobileResolution ? announcement?.smallMobile?.url : announcement?.mediumImage?.url
          }')`
        }}
      >
        <div className={classNames(styles.contentSection, styles.bigContentSection)}>
          <div className={styles.contentTop}>
            <div className={styles.category}>
              {announcement.category_text ?? AnnouncementCategoryLabels[announcement.category] ?? 'NEWS'}
            </div>
            <div className={styles.bigHeader}>{announcement.header}</div>
            {!isMobileResolution && <div className={styles.bigSubheader}>{announcement.subheader}</div>}
          </div>
          <div className={styles.bigAnnouncementBtnWrapper}>{renderButton(announcement, onButtonClick)}</div>
        </div>
      </div>
    </div>
  );

  const renderSmallAnnouncement = (announcement, position = 'inRight'): ReactElement => (
    <div onClick={() => onButtonClick(announcement)} className={classNames(styles.smallContainer, styles[position])}>
      <div
        className={classNames(styles.wrapper, styles[announcement?.category])}
        style={{
          borderRadius: '8px',
          backgroundSize: 'cover',
          backgroundImage: `url('${announcement?.smallMobile?.url}')`
        }}
      >
        <div className={classNames(styles.contentSection, styles.smallContentSection)}>
          <div className={styles.contentTop}>
            <div className={styles.category}>
              {announcement.category_text ?? AnnouncementCategoryLabels[announcement.category] ?? 'NEWS'}
            </div>
            <div className={styles.smallHeader}>{announcement.header}</div>
          </div>
          <div className={styles.smallAnnouncementBtnWrapper}>{renderButton(announcement, onButtonClick)}</div>
        </div>
      </div>
    </div>
  );

  return announcements?.enabled && announcements?.template == AnnouncementTemplateTypes.FULL ? (
    renderFullAnnouncement(announcements.announcements[0])
  ) : announcements?.enabled &&
    (announcements?.template == AnnouncementTemplateTypes.BIG_SMALL ||
      announcements?.template == AnnouncementTemplateTypes.SMALL_BIG) ? (
    <>
      {isMobileResolution ? (
        renderFullAnnouncement(announcements.announcements[0])
      ) : isSmallScreenResolution ? (
        renderFullAnnouncement(announcements.announcements[0])
      ) : (
        <div className={styles.doubleAnnouncementContainer}>
          {announcements?.template == AnnouncementTemplateTypes.BIG_SMALL ? (
            <>
              {renderBigAnnouncement(announcements.announcements[0], 'inLeft')}
              {renderSmallAnnouncement(announcements.announcements[1], 'inRight')}
            </>
          ) : (
            <>
              {renderSmallAnnouncement(announcements.announcements[0], 'inLeft')}
              {renderBigAnnouncement(announcements.announcements[1], 'inRight')}
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default Billboard;
